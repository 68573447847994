<template>
  <div class="mobile-footer">
    <div class="mobile-footer-qrlist">
      <div class="mfq-item">
        <img class="qrcode-image" src="@/assets/img/wx-code.png" alt="" />
        <br />
        <img class="qrcode-logo" src="@/assets/img/wx.png" alt="" />
      </div>
      <div class="mfq-item">
        <img class="qrcode-image" src="@/assets/img/dy-code.png" alt="" />
        <br />
        <img class="qrcode-logo" src="@/assets/img/dy.png" alt="" />
      </div>
      <div class="mfq-item">
        <img class="qrcode-image" src="@/assets/img/sp-code.png" alt="" />
        <br />
        <img class="qrcode-logo" src="@/assets/img/sp.png" alt="" />
      </div>
    </div>
    <div class="mf-title mf-title-phone" style="text-align: center">
      商业合作: 13319223597 其他业务: 17629092021
    </div>
    <div class="mf-title" style="text-align: center">
      Copyright 2023 陕西西部智联新能源产业集团有限公司
    </div>
    <div class="tips-list">
      <div class="mf-title" style="margin-right: 0.12rem">
        陕公网安备 61058102000305号
      </div>
      <div class="mf-title">陕ICP备2021015423号-1</div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.mobile-footer {
  background: #f3f3f3;
  .mobile-footer-qrlist {
    display: flex;
    padding: 0.2rem;
    justify-content: space-evenly;
    .mfq-item {
      text-align: center;
    }
    .qrcode-image {
      width: 0.7rem;
      height: 0.7rem;
    }
    .qrcode-logo {
      width: 0.28rem;
      height: 0.28rem;
      margin: 0 auto;
    }
  }
  .mf-title {
    font-size: 0.12rem;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 0.16rem;
  }
  .mf-title-phone {
    margin-bottom: 0.1rem;
    color: #333;
  }
  .tips-list {
    padding: 0.08rem 0.12rem;
    padding-bottom: 0.24rem;
    display: flex;
    justify-content: center;
  }
}
</style>
