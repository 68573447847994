import { isMobile } from '@/util/util'
// 平滑上划自定义指令
import Vue from 'vue'
// 移动距离
// const distance = 150
// 动画时间
// const duration = 800
//WeakMap避免内存泄露
const animationMap = new WeakMap()
//监听元素是否与当前页面视口重叠，即判断元素是否出现于当前视口内，返回所有元素监听结果
const ob = new IntersectionObserver(entries => {
  for (const entry of entries) {
    //判断元素是否在视口内
    if (entry.isIntersecting) {
      //获取当前在视口内的元素动画对象
      const animation = animationMap.get(entry.target)
      //开始执行动画
      animation.play()
      //执行完后取消监听，限制动画只播放一次，防止回滚时重复播放
      ob.unobserve(entry.target)
    }
  }
})
//判断元素是否在视口之下
function isBelowViewPort(el) {
  const rect = el.getBoundingClientRect()
  return rect.top > window.innerHeight
}

Vue.directive('slideIn', {
  inserted: function (el, binding) {
    let distance =
      binding.value && binding.value.distance ? binding.value.distance : 150
    let duration =
      binding.value && binding.value.duration ? binding.value.duration : 800
    if (isMobile()) {
      distance = 50
    }
    if (!isBelowViewPort(el)) {
      return
    }
    const animation = el.animate(
      [
        //配置关键帧start
        {
          transform: `translateY(${distance}px)`,
          opacity: 0.1
        },
        //配置关键帧end
        {
          transform: `translateY(0)`,
          opacity: 1
        }
      ],
      {
        //配置动画执行时间
        duration: duration,
        //配置动画时间函数
        easing: 'ease-in-out'
      }
    )
    //起始状态暂停动画执行，待元素在视口范围内执行动画
    animation.pause()
    //将元素与对象绑定进行映射
    animationMap.set(el, animation)
    //监听动画是否与视口重叠
    ob.observe(el)
  },
  //元素卸载时取消监听
  unbind: function (el) {
    ob.unobserve(el)
  }
})
