import Vue from 'vue'

let checkAndGetData;

Vue.directive('scroll',{
  bind: function (el, binding,vnode) {
    let cb_name = binding.expression,
    cb = vnode.context[cb_name];
    window.addEventListener('scroll', checkAndGetData = () => {
    //scrollTop是滚动条滚动时，距离顶部的距离
    let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    //windowHeight是可视区的高度
    let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
    //scrollHeight是滚动条的总高度
    let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    if(scrollTop + windowHeight == scrollHeight) {
      try{
        cb && cb();
      }
      catch(error) {
        console.log(error)
      }
    }
  }, true)
  },
  unbind: function () {
    window.removeEventListener('scroll', checkAndGetData, true)
  }
});
