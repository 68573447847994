<template>
  <div id="app">
    <entryFile v-if="isRouterAlive"></entryFile>
    <a
      href="https://xyt.xcc.cn/getpcInfo?sn=1796532107770535936&language=CN&certType=8&url=www.xibuzhilian.com"
      target="_blank"
      style="position: relative; display: none; height: 38px"
      rel="nofollow"
      >安全证书</a
    >
  </div>
</template>

<script>
import entryFile from './entryFile.vue'

export default {
  name: 'App',
  components: {
    entryFile
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true,
      hasMobileFlag: false
    }
  },
  // mounted() {
  //   window.addEventListener('resize', this.resetRender, true)
  // },
  // beforeDestroy() {
  //   window.removeEventListener('resize', this.resetRender, true)
  // },
  methods: {
    // resetRender() {
    //   if (isMobile()) {
    //     this.$router.go(0)
    //   }
    // },
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style scoped lang="scss">
// html{
//   display: flex;
// }
#app {
  // width: fit-content;
  height: 100%;
  min-width: 100%;
  font-family: 'PingFang SC-常规, PingFang SC;';
  .header-wrap {
    background: rgba(0, 0, 0, 0.6);
  }
}

// @media (max-width: 960px) {
//   #app {
//     position: fixed; /* 覆盖定位 */
//     top: 0;
//     left: 0;
//     z-index: 999; /* 设置层级，确保元素覆盖其他内容 */
//   }
// }
</style>
