<template>
  <transition name="fade">
    <div v-if="visible" id="toast" ref="toast">
      <div class="toast" :style="`width: ${width}`">
        <img v-if="icon" :src="require(`@/assets/img/message/${icon}.png`)" />
        {{ message }}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      message: "",
      duration: 3, // 显示时长，秒 
      closed: false, // 用来判断消息框是否关闭
      timer: null, // 计时器
      icon:  "",
      width: '',
    };
  },
  watch: {
    closed(val) {
      if (val) {
        this.visible = false;
        let toast = document.getElementById('toast');
        this.destroyElement(toast);
      }
    }
  },
  created() {
    let toast = document.getElementById('toast');
    if(toast){
      this.destroyElement(toast);
    }
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    //完全销毁实例
    destroyElement(dom) {
      this.$destroy();
      // this.$el.parentNode.removeChild(this.$el);
      document.body.removeChild(dom);
    },
    //计时器，根据 duration 去修改组件的开关状态
    startTimer() {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.closed = true;
          clearTimeout(this.timer);
        }
      }, this.duration * 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
#toast {
  z-index: 9999;
  position: fixed;
  top: 30px;
  left: 0;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  .toast {
    display: inline-block;
    max-width: 80vw;
    background: rgba(0, 0, 0, .5);
    padding: 14px 126px;
    text-align: center;
    color: #FFFFFF;
    font-size: 0.16rem;
    display: flex;
    align-items: center;
    border-radius: 4px;
    justify-content: center;
    img {
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }
}
.fade-enter-active {
  animation: fade 1s;
}
.fade-leave-active {
  animation: fade 1s reverse;
}
@keyframes fade {
  0% {
    transform: scale(1) translateX(200px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateX(0);
    opacity: 1;
  }
}

@media (max-width: $base-width){
  #toast {
    top: 40%;
    height: 0.44rem;
    .toast {
      max-width: 90vw;
      text-align: center;
      padding: 0.1rem 0.16rem;
      font-size: 0.21rem;
      img {
        width: 0.16rem;
        height: 0.16rem;
        margin-right: 0.12rem;
      }
    }
  }
}
</style>