const home = {
  state: {
    showMobile: false
  },
  mutations: {
    SET_SHOW_MOBILE: (state, status) => {
      state.showMobile = status
    }
  },
  actions: {
    setShowMobile({
      commit
    }, status) {
      commit('SET_SHOW_MOBILE', status)
    }
  }
}



export default home