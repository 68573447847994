import {
  isMobile
} from '@/util/util'
import store from '@/store/index'

(function (doc, win) {
  var docEL = document.documentElement,
    resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
    recalc = function () {
      // 获取当前设备的宽度
      let clientWidth = docEL.clientWidth;
      // let clientHeight = docEL.clientHeight;
      let {
        width,
        height
      } = window.screen;
      store.dispatch('setShowMobile', isMobile())
      if (isMobile()) {
        if (!clientWidth) return;
        // if (clientWidth >= 375) {
        //     //宽度大于375，根元素字体大小不能超过100px（不能大于设计稿的最大宽度）
        //     docEL.style.fontSize = "100px"; //设置根元素大小
        // } else {
        //     docEL.style.fontSize = (clientWidth / 375) * 100 + "px";
        // }
        docEL.style.fontSize = (clientWidth / 375) * 100 + "px";
      } else {
        let screenRatioByDesign = 16 / 9
        /**
         * height - clientHeight = 149
         * 屏幕高度 - 去除浏览器头部的高度
         * **/
        var screenRatio = width / (height - 149);
        var fontSize = (
          screenRatio > screenRatioByDesign ?
          (screenRatioByDesign / screenRatio) :
          1
        ) * clientWidth / 10;
        docEL.style.fontSize = fontSize.toFixed(3) + "px";
      }

    };
  if (!doc.addEventListener) return;
  //添加窗口变动监听
  win.addEventListener(resizeEvt, recalc, false);
  //dom加载的时候执行一次
  doc.addEventListener("DOMContentLoaded", recalc, false);
})(document, window);