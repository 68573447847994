// import Energy from '@/views/energy/energyIndex'

/**
 * 首页: home
 * 新能源: energy
 *  列表
 *  详情
 *
 * 换电站: replacementStation
 * 货运平台: freightplatform
 * 西部智联
 *  关于
 *  资讯(公司动态/产品资讯)
 *    详情
 *  加入
 * 登录注册:
 *  登录
 *  注册
 *  忘记密码
 *  隐私协议:
 * 404
 */
export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/homeIndex')
  },
  {
    path: '/vehicle',
    name: 'Vehicle',
    meta: { savePath: true }, // savePath记录父级path
    component: () => import('@/views/vehicle')
  },
  {
    path: '/vehicledetail',
    name: 'Vehicledetail',
    meta: { parentMenu: 'Vehicle' }, // parentMenu: 父级菜单name
    component: () => import('@/views/vehicle/vehicledetail')
  },
  {
    path: '/charge',
    name: 'Charge',
    meta: { savePath: true }, // savePath记录父级path
    component: () => import('@/views/energy/charge')
  },
  {
    path: '/chargeDetail',
    name: 'ChargeDetail',
    meta: { parentMenu: 'Charge' },
    component: () => import('@/views/energy/chargeDetail')
  },
  {
    path: '/chargeMoreDetail',
    name: 'ChargeMoreDetail',
    meta: { parentMenu: 'Charge' },
    component: () => import('@/views/energy/chargeMoreDetail')
  },
  {
    path: '/exchangeDetail',
    name: 'ExchangeDetail',
    meta: { parentMenu: 'Charge' },
    component: () => import('@/views/energy/exchangeDetail')
  },
  {
    path: '/exchange',
    name: 'Exchange',
    meta: { parentMenu: 'Charge', savePath: true },
    component: () => import('@/views/energy/exchange')
  },
  {
    path: '/power',
    name: 'Power',
    component: () => import('@/views/power')
  },
  {
    path: '/transport',
    name: 'Transport',
    component: () => import('@/views/transport')
  },
  {
    path: '/aftersales',
    name: 'Aftersales',
    component: () => import('@/views/customer/aftersales')
  },
  {
    path: '/finance',
    name: 'Finance',
    meta: { parentMenu: 'Aftersales' },
    component: () => import('@/views/customer/finance')
  },
  {
    path: '/software',
    name: 'Software',
    meta: { parentMenu: 'Aftersales' },
    component: () => import('@/views/customer/software')
  },
  {
    path: '/introduct',
    name: 'Introduct',
    component: () => import('@/views/company/introduct')
  },
  {
    path: '/news',
    name: 'News',
    meta: { parentMenu: 'Introduct', savePath: true },
    component: () => import('@/views/company/news')
  },
  {
    path: '/newdetail',
    name: 'Newdetail',
    meta: { parentMenu: 'Introduct' },
    component: () => import('@/views/company/newdetail')
  },
  {
    path: '/notification',
    name: 'Notification',
    meta: { parentMenu: 'Introduct', savePath: true },
    component: () => import('@/views/company/notification')
  },
  {
    path: '/notificationdetail',
    name: 'Notificationdetail',
    meta: { parentMenu: 'Introduct' },
    component: () => import('@/views/company/notificationdetail')
  },
  {
    path: '/join',
    name: 'Join',
    meta: { savePath: true },
    component: () => import('@/views/join')
  },
  {
    path: '/jobdetail',
    name: 'Jobdetail',
    meta: { parentMenu: 'Join' },
    component: () => import('@/views/join/jobdetail')
  },
  {
    path: '/joblist',
    name: 'Joblist',
    meta: { parentMenu: 'Join' },
    component: () => import('@/views/join/joblist')
  },
  {
    path: '/energy/:type',
    name: 'Energy',
    meta: { savePath: true },
    component: () => import('@/views/energy/energyIndex')
  },
  {
    path: '/energy/cardetail/:id',
    name: 'Cardetail',
    component: () => import('@/views/energy/energyDetail')
  },
  //新能源重卡更多推荐
  {
    path: '/energy/more/:type',
    name: 'More',
    component: () => import('@/views/energy/more')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/logReg/userLogin')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/logReg/userRegister')
  },
  {
    path: '/forgetPsd',
    name: 'ForgetPsd',
    component: () => import('@/views/logReg/forgetPsd')
  },
  {
    path: '/consult',
    name: 'consult',
    component: () => import('@/views/orderConsult')
  },
  {
    path: '/moblie-consult',
    name: 'moblie-consult',
    component: () => import('@/views/mobileConsult')
  },
  {
    path: '*',
    redirect: '/',
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/404Page.vue'),
    hidden: true
  },
  // 隐私条款页面
  {
    path: '/secret',
    name: 'secret',
    component: () => import('@/views/secret.vue'),
    hidden: true
  }
]
