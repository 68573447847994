// 招聘类型
export const recruitmentType = [
  { text: '社会招聘', value: 0 },
  { text: '校园招聘', value: 1 }
]
// 工作地点
export const workLocation = [
  { text: '西安', value: 0 },
  { text: '韩城', value: 1 },
  { text: '其他', value: 2 }
]
// 岗位类别
export const jobType = [
  { text: '研发类', value: 0 },
  { text: '技术类', value: 1 },
  { text: '运营类', value: 2 },
  { text: '采购类', value: 3 },
  { text: '技能类', value: 4 },
  { text: '财会类', value: 5 },
  { text: '销售类', value: 6 },
  { text: '其他', value: 7 }
]
// 头部导航栏
export const headMenuList = [
  { label: '首页', to: '/', name: 'Home' },
  {
    label: '新能源重卡',
    to: '/vehicle',
    name: 'Vehicle',
    children: [
      { to: '/vehicle?trucktype=1', label: '换电重卡' },
      { to: '/vehicle?trucktype=2', label: '氢能重卡' },
      { to: '/vehicle?trucktype=3', label: '超充重卡' }
    ]
  },
  {
    label: '能源配套',
    to: '/charge',
    name: 'Charge',
    children: [
      { to: '/charge', label: '快充方案' },
      { to: '/exchange', label: '换电方案' }
    ]
  },
  {
    label: '动力电池',
    to: '/power',
    name: 'Power',
    children: [{ to: '/power', label: '动力电池' }]
  },
  {
    label: '智慧运力',
    to: '/transport',
    name: 'Transport',
    children: [{ to: '/transport', label: '智慧运力' }]
  },
  {
    label: '客户服务',
    to: '/aftersales',
    name: 'Aftersales',
    children: [
      { to: '/aftersales', label: '售后服务' },
      { to: '/finance', label: '金融服务' },
      { to: '/software', label: '软件开发服务' }
    ]
  },
  {
    label: '关于我们',
    to: '/introduct',
    name: 'Introduct',
    children: [
      { to: '/introduct', label: '公司简介' },
      { to: '/news', label: '新闻中心' },
      { to: '/notification', label: '通知公告' }
    ]
  },
  {
    label: '加入我们',
    to: '/join',
    name: 'Join',
    children: [
      { to: '/join?type=0', label: '社会招聘' },
      { to: '/join?type=1', label: '校园招聘' }
    ]
  }
]
// 底部导航栏
export const footMenuList = [
  {
    label: '新能源重卡',
    to: '/vehicle',
    children: [
      { to: '/vehicle?trucktype=1', label: '换电重卡' },
      { to: '/vehicle?trucktype=2', label: '氢能重卡' },
      { to: '/vehicle?trucktype=3', label: '超充重卡' }
    ]
  },
  {
    label: '能源配套',
    to: '/charge',
    children: [
      { to: '/charge', label: '快充方案' },
      { to: '/exchange', label: '换电方案' }
    ]
  },
  {
    label: '动力电池',
    to: '/power',
    children: [{ to: '/power', label: '动力电池' }]
  },
  {
    label: '智慧运力',
    to: '/transport',
    children: [{ to: '/transport', label: '智慧运力' }]
  },
  {
    label: '客户服务',
    to: '/aftersales',
    children: [
      { to: '/aftersales', label: '售后服务' },
      { to: '/finance', label: '金融服务' },
      { to: '/software', label: '软件开发服务' }
    ]
  },
  {
    label: '关于我们',
    to: '/introduct',
    children: [
      { to: '/introduct', label: '公司简介' },
      { to: '/news', label: '新闻中心' },
      { to: '/notification', label: '通知公告' }
    ]
  },
  {
    label: '加入我们',
    to: '/join',
    children: [
      { to: '/join?type=0', label: '社会招聘' },
      { to: '/join?type=1', label: '校园招聘' }
    ]
  },
  {
    label: '联系我们',
    children: [
      { label: '商业合作: 13319223597' },
      { label: '其他业务: 17629092021' }
    ]
  }
]
// 常显导航栏list
export const showRouteList = [
  '/consult',
  '/secret',
  '/joblist',
  '/jobdetail',
  '/newdetail',
  '/chargeMoreDetail',
  '/notificationdetail'
]
