<template>
  <div class="wrap">
    <MobileHeader v-if="showMobile" />
    <Header v-else></Header>
    <div :class="showMobile ? 'mobile-main' : 'main'">
      <div :class="showMobile ? 'mobile-view' : 'main'">
        <router-view></router-view>
      </div>
      <MobileFooter v-if="showMobile" />
    </div>
    <Footer v-if="!showMobile"></Footer>
  </div>
</template>
<script>
import Header from '@/components/headerComp.vue'
import MobileHeader from '@/components/mobileHeaderComp.vue'
import Footer from '@/components/footerComp.vue'
import MobileFooter from '@/components/mobileFooterComp.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    Header,
    MobileHeader,
    Footer,
    MobileFooter
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  computed: {
    showHeader() {
      const url = ['/login', '/register', '/forgetPsd']
      return !url.includes(this.$route.path)
    },
    ...mapGetters(['showMobile'])
  }
}
</script>

<style scoped lang="scss">
.wrap {
  min-height: 100%;
  width: 100%;
  .main {
    display: flex;
    justify-content: center;
    min-height: 3.51rem;
    background: #fff;
    width: 100%;
  }
  .mobile-main {
    padding-top: 0.44rem;
    overflow-y: scroll;
    background: #fff;
    .mobile-view {
      min-height: calc(100vh - 2.6rem);
    }
  }
}
</style>
