<template>
  <div class="header-wrap" :class="{ scroll: tabbarShow || headerScrollFlag || mouseoverFlag }" @mouseenter="mouseoverFlag = true" @mouseleave="mouseoverFlag = false">
    <div class="header-main">
      <div class="menu">
        <router-link to="/" class="logo">
          <img v-if="tabbarShow || headerScrollFlag || mouseoverFlag" src="@/assets/img/logo-head.png" alt="" />
          <img v-else src="@/assets/img/logo-head-tou.png" alt="" />
        </router-link>
        <ul class="menu-list">
          <li class="menu-item" :class="{ active: isActiveMenu === item.name }" v-for="(item, index) in menuList" :key="index" ref="item">
            <router-link :to="item.to" class="label">{{ item.label }}</router-link>
            <ol class="menu-child" v-if="item.children">
              <li v-for="(itm, idx) in item.children" :key="idx">
                <router-link :to="itm.to">{{ itm.label }}</router-link>
              </li>
            </ol>
          </li>
        </ul>
      </div>
      <div class="right">
        <router-link target="_blank" class="consult" to="/consult">预约咨询</router-link>
        <!-- <div class="login-info">
          <div v-if="!nickName" class="no-login">
            <router-link to="/login">登录/</router-link>
            <router-link to="/register">注册</router-link>
          </div>
          <div v-else class="nick-name">
            <a>{{ nickName }}</a>
            <div class="nickName-dialog-wrap">
              <ul>
                <li @click="handleLogout">退出登录</li>
              </ul>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from '@/request/api'
import { headMenuList, showRouteList } from '@/util/data'
export default {
  inject: ['reload'],
  data() {
    return {
      // 鼠标滑过
      mouseoverFlag: false,
      // 导航栏是否在顶部
      headerScrollFlag: true,
      // 导航栏常显
      tabbarShow: false,
      // 昵称
      nickName: localStorage.getItem('nickName'),
      // 导航栏
      menuList: headMenuList,
      menuElements: [],
      isActiveMenu: 'Home' // 当前激活菜单
    }
  },
  watch: {
    $route: {
      handler(newVal, oldVal) {
        if (!showRouteList.includes(this.$route.path)) {
          this.tabbarShow = false
        } else {
          this.tabbarShow = true
        }
        let currRouteName = newVal.meta.parentMenu || newVal.name
        this.isActiveMenu = currRouteName || 'Home'
      }
    }
  },
  mounted() {
    this.menuElements = this.$refs.item
    window.addEventListener('scroll', this.scrollBarMove, true)
    // 如果menu-child标签的li便签只有一个子标签, 则给这个子标签设置border-radius为8px
    let menuChild = document.querySelectorAll('.menu-child')
    menuChild.forEach(item => {
      let child = item.querySelectorAll('li')
      if (child.length === 1) {
        child[0].style.borderRadius = '8px'
      }
    })
  },
  methods: {
    /**
     * 监听滚动条, 超出导航栏高度,  修改头部样式
     */
    scrollBarMove() {
      let h = document.documentElement.scrollTop || document.body.scrollTop
      this.headerScrollFlag = h > 80
    },
    // 退出登录
    handleLogout() {
      logout().then(() => {
        localStorage.clear()
        this.reload()
      })
    }
  }
}
</script>
<style scoped lang="scss">
.header-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.37rem;
  background: transparent;
  z-index: 999;
  font-size: 0.093rem;
  transition: all 0.3s ease;
  .header-main {
    // width: 8.65rem;
    width: 10rem;
    white-space: nowrap;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .menu {
    display: flex;
    height: 100%;
    align-items: center;

    .logo {
      margin-right: 0.35rem;
      width: 0.76rem;
      overflow: hidden;
      cursor: pointer;
      font-size: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .menu-list {
      display: flex;
      height: 100%;
      .menu-item {
        position: relative;
        margin-right: 0.15rem;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        &:last-child {
          margin-right: 0;
        }
        span {
          border-bottom: 3px solid transparent;
          padding: 0.07rem;
        }
        .label {
          padding: 0.15rem 0;
        }
        .menu-child {
          visibility: hidden;
          position: absolute;
          top: 0.37rem;
          left: 50%;
          transform: translateX(-50%);
          background: transparent;
          border-radius: 8px;
          padding-top: 8px;
          opacity: 0;
          transition: all 0.4s ease;
          > li {
            // width: 0.75rem;
            padding: 0 0.15rem;
            height: 0.37rem;
            line-height: 0.37rem;
            text-align: center;
            background: #fff;
            &:first-child {
              border-radius: 8px 8px 0 0;
            }
            &:last-child {
              border-radius: 0 0 8px 8px;
            }
            a {
              border-radius: 8px;
              display: block;
              color: #333;
            }
            &:hover {
              a {
                color: #999999;
              }
            }
          }
        }
        &:hover {
          span {
            border-bottom: 3px solid #2b87ff;
          }
          .menu-child {
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .menu-item::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 0;
        height: 3px;
        background-color: #2b87ff;
        transform: translate(-50%);
        transition: all 0.4s;
      }
      .menu-item:hover::before {
        width: 100%;
      }
      .active::before {
        width: 100%;
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    height: 100%;
    .consult {
      margin-right: 0.25rem;
    }
    .login-info {
      cursor: pointer;
      height: 100%;
      .no-login {
        display: flex;
        align-items: center;
        height: 100%;
      }
      .nick-name {
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        .nickName-dialog-wrap {
          padding-top: 8px;
          position: absolute;
          top: 0.37rem;
          left: 50%;
          transform: translateX(-50%);
          width: 120px;
          visibility: hidden;
          opacity: 0;
          transition: all 0.4s ease;
          ul {
            text-align: center;
            background: #fff;
            border-radius: 8px;
            li {
              height: 0.37rem;
              line-height: 0.37rem;
              &:hover {
                color: #999999;
              }
            }
          }
        }
        &:hover {
          .nickName-dialog-wrap {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  a {
    color: #fff;
  }
  &.scroll {
    background: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
    a {
      color: #333;
    }
  }
}
</style>
