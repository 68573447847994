import Vue from 'vue'
import App from './views/App.vue'
import router from './router/index'
import infiniteScroll from 'vue-infinite-scroll'
import './directive'
import '@/assets/css/common.css'
import '@/assets/css/animate.css'
import store from './store'

import { isMobile } from '@/util/util'

import tools from '@/util/tools.js'
Vue.use(tools)
Vue.use(infiniteScroll)

import Carouseled from 'vue-carousel-3d'
// import { Carousel, CarouselItem, Loading } from 'element-ui'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import echarts from 'echarts'
import china from 'echarts/map/json/china.json'
echarts.registerMap('china', china)
Vue.prototype.$echarts = echarts

import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Video
// Vue.prototype.$isMobile = isMobile()

import Scrollmagic from 'scrollmagic'

//图片预览
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    zIndex: 9999,
    inline: true, //启用lnline模式
    button: true, //显示右上角关闭按钮
    navbar: true, //显示缩略图导航
    title: true, //显示当前图片的标题
    toolbar: true, //显示工具栏
    tooltip: true, //显示缩放百分比
    movable: true, //显示可移动
    zoomable: true, //图片是否可缩放
    rotatable: true, //图片是否可旋转
    scalable: true, //图片是否可翻转
    transition: true, //使用css3过度
    fullscreen: true, //播放时是否全屏
    keyboard: true, //是否支持键盘
    // url: 'data-source' //设置大图片的url
    url: 'data-href'
  }
})
Vue.use(Scrollmagic)

Vue.use(Carouseled)
// Vue.use(Carousel)
// Vue.use(CarouselItem)
// Vue.use(Loading)
Vue.use(ElementUI)
Vue.config.productionTip = false

/**移动端库vant */

import {
  Popup,
  Picker,
  Tab,
  Tabs,
  Swipe,
  SwipeItem,
  Search,
  DropdownMenu,
  DropdownItem,
  Form,
  Field,
  Button,
  RadioGroup,
  Radio,
  Checkbox,
  Toast 
} from 'vant'
Vue.use(Popup)
  .use(Picker)
  .use(Tab)
  .use(Tabs)
  .use(Swipe)
  .use(SwipeItem)
  .use(Search)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Form)
  .use(Field)
  .use(Button)
  .use(RadioGroup)
  .use(Radio)
  .use(Checkbox)
  .use(Toast)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// 设置根元素fontSize
import '@/util/setFontSize'

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')

if (isMobile()) {
  // Vue.use(VueTouch, { name: 'v-touch' });
  // VueTouch.config.swipe = {
  //   threshold: 50
  // };
  var oMeta = document.createElement('meta')
  oMeta.content =
    'width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0, user-scalable=0'
  oMeta.name = 'viewport'
  document.getElementsByTagName('head')[0].appendChild(oMeta)
}
