<template>
  <div class="moblie-header-wrap">
    <div class="moblie-header-content">
      <img
        class="mobile-logo"
        src="@/assets/img/logo-head.png"
        alt=""
      />
      <div class="moblie-header-right">
        <router-link class="consult" to="/moblie-consult">预约咨询</router-link>
        <img
          v-if="menuChecked"
          class="mobile-menu-btn"
          src="@/assets/img/home/menu-close.png"
          @click="closeMenu"
          alt=""
        />
        <img
          v-if="!menuChecked"
          class="mobile-menu-btn"
          src="@/assets/img/home/menu.png"
          @click="openMenu"
          alt=""
        />
      </div>
    </div>
    <div class="header-main">
      <div class="mobile-menu">
        <el-menu
          :default-active="activedMenu"
          :unique-opened="true"
          :class="menuChecked ? 'mobile-menu-list down' : 'mobile-menu-list up'"
          @select="checkMenu"
        >
          <div
            v-for="(item, index) in menuList"
            :key="index"
          >
            <el-submenu
              v-if="item.children"
              ref="item"
              :index="item.to"
            >
              <template slot="title">
                <span>{{ item.label }}</span>
              </template>
              <el-menu-item-group
                v-for="(itm, idx) in item.children"
                :key="idx"
              >
                <el-menu-item :index="itm.to">{{ itm.label }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item
              v-else
              :index="item.to"
            >
              <span>{{ item.label }}</span>
            </el-menu-item>
          </div>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { headMenuList } from '@/util/data'
import { routes } from '@/router/routers'

export default {
  data() {
    return {
      menuChecked: false,
      // 昵称
      nickName: localStorage.getItem('nickName'),
      // 导航栏
      menuList: headMenuList,
      activedMenu: '/'
    }
  },
  watch: {
    $route(vl) {
      this.menuChecked = false
      const activedRoute = localStorage.getItem('activedRoute')
      this.activedMenu = activedRoute || vl.fullPath
      if (!this.activedMenu) {
        this.activedMenu = '/'
      }
    }
  },
  methods: {
    checkMenu(index) {
      const activedRoute = routes.find(vl => {
        return (
          index.split('/')[1].startsWith(vl.path.split('/')[1]) &&
          vl.meta &&
          vl.meta.savePath
        )
      })
      if (activedRoute) {
        localStorage.setItem('activedRoute', index)
      } else {
        localStorage.removeItem('activedRoute')
      }

      this.$router.push(index)
    },
    closeMenu() {
      this.menuChecked = !this.menuChecked
      const mainDom = document.getElementsByClassName('mobile-main')[0]
      if (mainDom) mainDom.style.overflowY = 'auto'
    },
    openMenu() {
      this.menuChecked = !this.menuChecked
      const mainDom = document.getElementsByClassName('mobile-main')[0]
      if (mainDom) mainDom.style.overflowY = 'hidden'
    }
  }
}
</script>

<style lang="scss" scoped>
.moblie-header-wrap {
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  z-index: 999;
  .moblie-header-content {
    height: 0.44rem;
    padding: 0 0.12rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .moblie-header-right {
    display: flex;
    justify-items: center;
    font-size: 0.14rem;
    font-weight: 400;
    color: #333333;
    line-height: 0.2rem;
    .consult {
      margin-right: 0.14rem;
      font-size: 0.14rem;
    }
  }
  .mobile-logo {
    width: 0.9294rem;
    height: 0.2914rem;
  }
  .mobile-menu-btn {
    width: 0.2rem;
    height: 0.2rem;
  }
  .mobile-menu-list {
    height: unset;
    overflow: hidden;
    will-change: height;
    transition: all 0.3s cubic-bezier(0.8, 0, 0, 0.8);
  }
  .up {
    height: 0;
  }
  .down {
    height: calc(100vh - 0.44rem);
  }
  :deep(.el-menu--inline) {
    background: #f3f3f3;
  }
  :deep(.el-menu-item) {
    color: #999999;
  }
  :deep(.el-submenu__title) {
    color: #999999;
    &:hover {
      background: #ffffff;
      color: #303133;
    }
  }
  :deep(.is-active) {
    color: #409eff !important;
  }
  :deep(.el-menu) {
    border: none;
  }
}
</style>
