import Vue from 'vue';
import Main from './main.vue'
// 创建构造器
let ToastConstructor = Vue.extend(Main);
let instance
let seed = 1 // 计数
const Toast = (options = {}) => {
    //判断如果传入的是一个字符串，那么就使用message提示
    if (typeof options === 'string') {
        options = {
            message: options
        }
    }
    let id = `toast_${seed++}`
    instance = new ToastConstructor({
        data: options
    })// 创建对象实例
    instance.id = id
    // 手动挂载一个实例并插入到 body 中
    instance.$mount()
    document.body.appendChild(instance.$el)
    instance.visible = true
    return instance
}

export default Toast