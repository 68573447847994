import request from './http.js'
// 工作类型
const jobType = function (data) {
  return request({
    url: '/api/joinUs/selectAll',
    method: 'get',
    data: data
  })
}
// 工作列表
const jobList = function (data) {
  return request({
    url: '/api/joinUs/select',
    method: 'get',
    data: data
  })
}
// 工作详情
const jobDetail = function (data) {
  return request({
    url: '/api/joinUs/selectOne',
    method: 'get',
    data: data
  })
}
// 注册
const register = function (data) {
  return request({
    url: '/api/member/register',
    method: 'get',
    data: data
  })
}
// 短信验证码
const verifyCode = function (data) {
  return request({
    url: '/sms/send/verifyCode',
    method: 'get',
    data: data
  })
}
const login = function (data) {
  return request({
    url: '/api/member/login',
    method: 'post',
    data: data
  })
}
const logout = function (data) {
  return request({
    url: '/api/member/logout',
    method: 'delete',
    data: data
  })
}
const forgetPassword = function (data) {
  return request({
    url: '/api/member/password',
    method: 'post',
    data: data
  })
}
const appointment = function (data) {
  return request({
    url: '/api/appointment',
    method: 'post',
    data: data
  })
}
const banner = function (data) {
  return request({
    url: '/api/vehicle/banner',
    method: 'get',
    data: data
  })
}
/**
 * 获取省市数据
 * @param {*} data 
 * @returns 
 */
const address = function (data) {
  return request({
    url: '/api/appointment/address',
    method: 'get',
    data: data
  })
}

const compantNoticeList = function (data) {
  return request({
    url: '/api/companyNotice/list',
    method: 'get',
    data: data
  })
}
const companyNotice = function (data) {
  return request({
    url: '/api/companyNotice/list' + data.id,
    method: 'get',
    data: data
  })
}
const productNoticeList = function (data) {
  return request({
    url: '/api/productNotice/list',
    method: 'get',
    data: data
  })
}
const informNoticeList = function (data) {
  return request({
    url: '/api/announcement/list/terminal',
    method: 'get',
    data: data
  })
}
const informNoticeDetail = function (data) {
  return request({
    url: '/api/announcement/id',
    method: 'get',
    data: data
  })
}
const fileDownload = function (data) {
  return request({
    url: '/api/announcement/download',
    method: 'post',
    data: data,
    responseType: 'blob'
  })
}
const vehicleType = function (data) {
  return request({
    url: '/api/vehicle/type',
    method: 'get',
    data: data
  })
}
const getFileName = function (data) {
  return request({
    url: '/api/announcement/attachments',
    method: 'get',
    data: data
  })
}
const vehicleSearch = function (data) {
  return request({
    url: '/api/vehicle/search',
    method: 'get',
    data: data
  })
}

const vehicleData = function (data) {
  return request({
    url: '/api/vehicle',
    method: 'get',
    data: data
  })
}

const vehicleList = function (data) {
  return request({
    url: '/api/vehicle/list',
    method: 'get',
    data: data
  })
}
const vehicleDetail = function (data) {
  return request({
    url: '/api/vehicle/detail/' + data.id,
    method: 'get'
  })
}
const vehicleFinancial = function (data) {
  return request({
    url: '/api/vehicle/financial',
    method: 'get',
    data: data
  })
}

// 获取新闻列表
const getNewsList = function (data) {
  return request({
    url: '/api/newsCenter/select',
    method: 'get',
    data: data
  })
}
// 获取新闻详情
const getNewsDetail = function (data) {
  return request({
    url: '/api/newsCenter/detail',
    method: 'get',
    data: data
  })
}
export {
  jobType,
  jobList,
  jobDetail,
  address,
  register,
  verifyCode,
  login,
  logout,
  forgetPassword,
  appointment,
  banner,
  compantNoticeList,
  companyNotice,
  productNoticeList,
  vehicleData,
  vehicleType,
  vehicleSearch,
  vehicleDetail,
  vehicleFinancial,
  vehicleList,
  informNoticeList,
  informNoticeDetail,
  getFileName,
  fileDownload,
  getNewsList,
  getNewsDetail
}
