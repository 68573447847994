import { render, staticRenderFns } from "./mobileFooterComp.vue?vue&type=template&id=d7a08190&scoped=true"
import script from "./mobileFooterComp.vue?vue&type=script&lang=js"
export * from "./mobileFooterComp.vue?vue&type=script&lang=js"
import style0 from "./mobileFooterComp.vue?vue&type=style&index=0&id=d7a08190&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7a08190",
  null
  
)

export default component.exports