<template>
  <div class="footer-wrap">
    <div class="footer-main">
      <div class="tab-wrap">
        <ul class="tab-list">
          <li class="tab-item" v-for="(item, index) in menuList" :key="index">
            <div class="tit">{{ item.label }}</div>
            <p v-for="(itm, idx) in item.children" :key="idx" class="tab">
              <router-link v-if="itm.to" :to="itm.to">{{ itm.label }}</router-link>
              <span v-else class="phone">{{ itm.label }}</span>
            </p>
          </li>
        </ul>
        <div class="qr-code">
          <div class="tit">关注我们</div>
          <div class="icon">
            <img :src="require('@/assets/img/wx.png')" alt="" @mouseenter="mouseover='wx'">
            <img :src="require('@/assets/img/dy.png')" alt="" @mouseenter="mouseover='dy'">
            <img :src="require('@/assets/img/sp.png')" alt="" @mouseenter="mouseover='sp'">
          </div>
          <div class="code-img">
            <img v-if="mouseover==='wx'" :src="require('@/assets/img/wx-code.png')">
            <img v-if="mouseover==='dy'" :src="require('@/assets/img/dy-code.png')">
            <img v-if="mouseover==='sp'" :src="require('@/assets/img/sp-code.png')">
          </div>
        </div>
      </div>
      <div class="copy-right">
        <span class="company">
          Copyright&nbsp;{{ new Date().getFullYear() }}
          &nbsp;陕西西部智联新能源产业集团有限公司&nbsp;
        </span>
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61058102000305"
          class="police-beian"
        >
          <img src="@/assets/img/beian.png" />
          <span>陕公网安备 61058102000305号</span>
        </a>
        <a href="https://beian.miit.gov.cn"
          target="_blank"
          rel="nofollow"
          class="police-beian"
          >陕ICP备2021015423号-1</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { footMenuList } from '@/util/data'
export default {
  data() {
    return {
      menuList: footMenuList,
      mouseover: 'wx'
    }
  },
  methods: {
    
  }
}
</script>
  
<style scoped lang="scss">
.footer-wrap {
  width: 100%;
  background: #F3F3F3;
  .footer-main {
    position: relative;
    width: 8.65rem;
    margin: 0 auto;
    font-size: .08rem;
  }
  .tab-wrap{
    display: flex;
    padding: .25rem .16rem;
    .tab-list{
      display: flex;
      flex: 1;
      justify-content: space-around;
      .tab-item{
        // margin-right: .4rem;
        .tit{
          margin-bottom: .13rem;
          color: #333;
          font-size: .1rem;
        }
        .tab{
          margin-bottom: .13rem;
          a, span{
            font-size: .08rem;
            color: #999999;
          }
          .phone {
            color: #333;
          }
          a:hover{
            color:#2B87FF
          }
        }
      }
    }
    .qr-code{
      display: flex;
      flex-direction: column;
      .tit{
        color: #333;
        margin-bottom: .13rem;
        font-size: .1rem;
      }
      .icon{
        display: flex;
        img{
          width: 0.28rem;
          height: 0.28rem;
          cursor: pointer;
          
          &:nth-child(2) {
            margin: 0 0.1rem;
          }
        }
      }
      .code-img{
        width: .7rem;
        height: .7rem;
        margin: 0 auto;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .copy-right {
    display: flex;
    align-items: center;
    justify-content: center;
    height: .34rem;
    line-height: .34rem;
    border-top: 1px solid #E9E9E9;
    font-size: .08rem;
    .police-beian {
      margin: 0 .1rem 0;
      display: flex;
      align-items: center;
      text-decoration: none;
      font-size: .08rem;
      img {
        margin-right: .04rem;
        width: .09rem;
        height: .09rem;
      }
      p {
        margin-left: 5px;
      }
    }
  }
}
</style>
