import Vue from 'vue'
 /**弹窗滚动底部页面不滚动 */
Vue.directive('fixed',{
  inserted () {
    // let scrollTop = document.body.scrollTop || document.documentElement.scrollTop
    let scrollY = window.scrollY || 0
    // document.body.style = `position:fixed;top:${-scrollY};height:100%;width:100%;overflow: hidden;`
    document.body.style.cssText += `position:fixed;width:100%;height:100%;;top:${-scrollY}px;`;
  },
  unbind() {
    let body = document.body
    body.style.position = ''
    let top = body.style.top
    document.body.scrollTop = document.documentElement.scrollTop = -parseInt(top)
    body.style.top = ''
  }
})