/**
 * ---------------- 常用正则表达式 ----------------
 */
// 数字、大写字母匹配
export const REGSTR_AZ_09 = 'A-Z0-9'
// 数字、大写字母匹配
export const REGSTR_AZ_az_09 = 'A-Za-z0-9'
// 汉字匹配
export const REGSTR_HANZI = '\\u4E00-\\u9FFF'
// 汉字、英文大小写
export const REGSTR_HANZI_AZ_az = '\\u4E00-\\u9FFFA-Za-z'
// 汉字、英文大小写、数字
export const REGSTR_HANZI_AZ_az_09 = '\\u4E00-\\u9FFFA-Za-z0-9'
export const REG_mobilePhone =
  /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/
export const REG_password = /^.{8,16}$/
//大于0的数字
export const REGSTR_19 = /^([1-9][0-9]*)$/

// 移动端
export const isMobile = () => {
  let reg =
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  let width = window.innerWidth
  return reg.test(navigator.userAgent) || width < 810
}
