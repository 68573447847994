/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    let part = encodeURIComponent(propName) + '='
    if (value !== null && value !== '' && typeof value !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (
            value[key] !== null &&
            value[key] !== '' &&
            typeof value[key] !== 'undefined'
          ) {
            let params = propName + '[' + key + ']'
            let subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    } else {
      result += part + '&'
    }
  }
  return result
}

/**
 * 根据文件后缀名 文件存储路径
 *  String documents = "txt doc pdf ppt pps xlsx xls docx";
    String music = "mp3 wav wma mpa ram ra aac aif m4a";
    String video = "avi mpg mpe mpeg asf wmv mov qt rm mp4 flv m4v webm ogv ogg";
    String image = "bmp dib pcp dif wmf gif jpg tif eps psd cdr iff tga pcd mpt png jpeg";

    @param {Filename} filename
 */
export function judgmentPath(filename) {
  if (!filename) return ''
  let strToArr = filename.split('.')
  let fileExtension = strToArr[strToArr.length - 1]

  let documents = 'txt doc pdf ppt pps xlsx xls docx'
  let music = 'mp3 wav wma mpa ram ra aac aif m4a'
  let video = 'avi mpg mpe mpeg asf wmv mov qt rm mp4 flv m4v webm ogv ogg'
  let image =
    'bmp dib pcp dif wmf gif jpg tif eps psd cdr iff tga pcd mpt png jpeg'
  if (image.indexOf(fileExtension) > -1) {
    return '图片'
  } else if (video.indexOf(fileExtension) > -1) {
    return '视频'
  } else if (music.indexOf(fileExtension) > -1) {
    return '音乐'
  } else if (documents.indexOf(fileExtension) > -1) {
    return '文档'
  } else {
    return '其他'
  }
}

/**
 * 文件下载
 *  @param {obj} obj  请求接口返回的数据
 *  @param {name} obj  文件名
 *  @param {suffix} obj  文件格式
 */
export function downloadFile(obj, name) {
  const url = window.URL.createObjectURL(
    new Blob([obj], { type: 'application/octet-stream;charset=utf-8' })
  )
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
